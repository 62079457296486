import Layout2 from '@/layout/Layout2'

export default [{
  path: '/configuracion',
  component: Layout2,
  children: [{
    path: 'listaUsuarios',
    component: () => import('@/components/configuracion/listaUsuarios')
  },
  {
    path: 'usuarios/:idUsuario',
    component: () => import('@/components/configuracion/usuarios')
  },
  {
    path: 'galeriaInicio',
    component: () => import('@/components/configuracion/galeriaInicio')
  },
  {
    path: 'repBusquedas',
    component: () => import('@/components/configuracion/repBusquedas')
  },
  {
    path: 'listaDescuentos/',
    component: () => import('@/components/configuracion/listaDescuentos')
  },
  {
    path: 'listaCupones',
    component: () => import('@/components/configuracion/listaCupones')
  },
  {
    path: 'cupones/:idCupon',
    component: () => import('@/components/configuracion/cupones')
  },
  {
    path: 'ajustes',
    component: () => import('@/components/configuracion/ajustes')
  }]
}]
