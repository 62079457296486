import LayoutBlank from '@/layout/LayoutBlank'

export default [{
  path: '/auth',
  component: LayoutBlank,
  children: [{
    path: 'login',
    component: () => import('@/components/auth/login')
  }]
}]
