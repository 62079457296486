import Layout2 from '@/layout/Layout2'

export default [{
  path: '/inventario',
  component: Layout2,
  children: [
    {
      path: 'ubicaciones/:idUbicaciones',
      component: () => import('@/components/inventario/ubicaciones')
    },
    {
      path: 'listaUbicaciones/',
      component: () => import('@/components/inventario/listaUbicaciones')
    },
    {
      path: 'listaAlmacenes/',
      component: () => import('@/components/inventario/listaAlmacenes')
    },
    {
      path: 'almacenes/:idAlmacen',
      component: () => import('@/components/inventario/almacenes')
    },
    {
      path: 'listaCategorias/',
      component: () => import('@/components/inventario/listaCategorias')
    },
    {
      path: 'categorias/:idCategoria',
      component: () => import('@/components/inventario/categorias')
    },
    {
      path: 'listaArticulos/',
      component: () => import('@/components/inventario/listaArticulos')
    },
    {
      path: 'Articulos/:idArticulo',
      component: () => import('@/components/inventario/Articulos')
    },
    {
      path: 'listaOfertas/',
      component: () => import('@/components/inventario/listaOfertas')
    }]
}]
