<template>
  <sidenav :orientation="orientation" :class="curClasses">
    <!--- NAVEGACION REAL NO FAKE --->
    <!-- Brand demo (see src/demo.css) -->
    <!--
    <div class="app-brand demo" v-if="orientation !== 'horizontal'" v-html="this.$logo_alterno">
    </div>
    -->
    <div class="app-brand demo" v-if="orientation !== 'horizontal'">
      Neptune Baazar
    </div>
    <div class="sidenav-divider mt-0" v-if="orientation !== 'horizontal'"></div>

    <!-- Links -->
    <div class="sidenav-inner" :class="{ 'py-1': orientation !== 'horizontal' }">
      <!-- Inventario -->
      <sidenav-router-link icon="ion ion-md-cube" to="/inventario/listaArticulos" :exact="true">Inventarios</sidenav-router-link>

    <!-- LINEA DE SEPARACION HORIZONTAL -->
    <div class="sidenav-divider mt-0" v-if="orientation !== 'horizontal'"></div>

    <!-- Configuracion -->
    <div v-if="idTipoUsuario === '3'">
      <sidenav-router-link icon="ion ion-md-settings" to="/configuracion/galeriaInicio" :exact="true">Configuración</sidenav-router-link>
    </div>

    </div>
  </sidenav>
</template>

<script>
import { Sidenav, SidenavLink, SidenavRouterLink, SidenavMenu, SidenavHeader, SidenavBlock, SidenavDivider } from '@/vendor/libs/sidenav'
// const axios = require('axios')
export default {
  name: 'app-layout-sidenav',
  components: {
    // NAVBAR
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider
    /* eslint-enable vue/no-unused-components */
  },

  props: {
    orientation: {
      type: String,
      default: 'vertical'
    }
  },
  data: () => ({
    idTipoUsuario: 3
  }),
  computed: {
    curClasses () {
      let bg = this.layoutSidenavBg

      if (this.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
        bg = bg
          .replace(' sidenav-dark', '')
          .replace(' sidenav-light', '')
          .replace('-darker', '')
          .replace('-dark', '')
      }

      return `bg-${bg} ` + (
        this.orientation !== 'horizontal'
          ? 'layout-sidenav'
          : 'layout-sidenav-horizontal container-p-x flex-grow-0'
      )
    }
  },
  created () {
    var self = this
    self.idTipoUsuario = window.$cookies.get('idTipoUsuario')
    // console.log('self.idTipoUsuario--*-')
    // console.log(self.idTipoUsuario)
  },
  methods: {
    isMenuActive (url) {
      return this.$route.path.indexOf(url) === 0
    },

    isMenuOpen (url) {
      return this.$route.path.indexOf(url) === 0 && this.orientation !== 'horizontal'
    },

    toggleSidenav () {
      this.layoutHelpers.toggleCollapsed()
    }
  }
}
</script>
